<div class="{{ dateSectionClasses }}">
  <div class="text-center">
    <p class="text-3xl">
      <strong>{{ diaSemana }}</strong>
    </p>
    <p class="text-8xl py-px">
      <strong>{{ dia }}</strong>
    </p>
    <p class="text-xl">
      <strong>{{ mes }} {{ anio }}</strong>
    </p>
  </div>
</div>
