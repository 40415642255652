import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { LogoComponent } from './components/logo/logo.component';
import { IndicatorsComponent } from './components/indicators/indicators.component';
import { QueueComponent } from './components/queue/queue.component';
import { SliderComponent } from './components/slider/slider.component';
import { WeatherComponent } from './components/weather/weather.component';
import { HourComponent } from './components/hour/hour.component';
import { DateComponent } from './components/date/date.component';
import { ScreenComponent } from './components/screen/screen.component';
import { AppRoutingModule } from './app-routing.module';
import { RegisterComponent } from './components/register/register.component';
import { ErrorViewComponent } from './components/error-view/error-view.component';


@NgModule({
  declarations: [
    AppComponent,
    LogoComponent,
    IndicatorsComponent,
    QueueComponent,
    SliderComponent,
    WeatherComponent,
    HourComponent,
    DateComponent,
    ScreenComponent,
    RegisterComponent,
    ErrorViewComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
