<div class="{{ indicatorsSectionClasses }}">
  <div class="flex items-center px-12 py-3">
    <p class="text-[22px] font-bold pr-3">INDICADORES</p>
    <img class="w-5 h-5" src="assets\img\chile.png" />
  </div>

  <div class="space-y-1.5">
    <div
      class="bg-zinc-800 rounded-[5px] px-7 py-3"
      *ngFor="let item of _configIndicators"
    >
      <div>
        <p class="text-white font-medium text-2xl">
          {{ item.name.toUpperCase() }}
        </p>
        <div class="grid grid-cols-2">
          <p class="text-white text-xl text-start">
            $
            {{ item.value.toLocaleString("es-CL") }}
          </p>
          <!-- <p [ngClass]="{ 'text-stone-300': item.percentageValue == 0, 'text-red-500': item.percentageValue < 0, 'text-lime-600': item.percentageValue > 0 }" class="text-xl text-end">{{ item.percentage }}</p> -->
        </div>
      </div>
    </div>
  </div>
</div>
