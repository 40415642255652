import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ScreenComponent } from './components/screen/screen.component';
import { RegisterComponent } from './components/register/register.component';
import { ErrorViewComponent } from './components/error-view/error-view.component';


const routes: Routes = [
  { path: 'screens/:screenId', component: ScreenComponent },
  { path: 'register', component: RegisterComponent },
  {
    path: '**', pathMatch: 'full',
    component: ErrorViewComponent
  },
  {
    path: 'error', pathMatch: 'full',
    component: ErrorViewComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    bindToComponentInputs: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
