import gql from 'graphql-tag';

export const onScreenUpdate = gql`
  subscription OnScreenUpdate($name: String!) {
    subscribe(name: $name) {
      data
      name
    }
  }
`;
