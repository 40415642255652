import { Component } from '@angular/core';
import { EventType, Router } from '@angular/router';

@Component({
  selector: 'app-error-view',
  templateUrl: './error-view.component.html',
  styleUrls: ['./error-view.component.css']
})
export class ErrorViewComponent {
  statusCode: number = 404;
  message: string = "NOT FOUND :(";
  prevRoute: string = "..."
  constructor(private router: Router) {
    this.router.events.subscribe((evnt) => {
      if (evnt.type === EventType.NavigationEnd) {
        const navigation = this.router.getCurrentNavigation();
        const state = navigation?.extras.state as {
          STATUS: number,
          MESSAGE: string,
          PREV_ROUTE: string
        };
        this.statusCode = state.STATUS;
        this.message = state.MESSAGE.length == 0 ? "ERROR" : state.MESSAGE;
        this.prevRoute = state.PREV_ROUTE;
      }
    })
  }
}
