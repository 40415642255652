import { Component, Input, OnInit } from '@angular/core';
import { WeatherResponse, WeatherService } from '../../services/weather/weather.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { interval } from 'rxjs';


@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.css']
})
export class WeatherComponent implements OnInit {
  @Input() screenId: string = "";

  weatherSectionClasses: string = "absolute top-[50%] right-0 w-[45%] flex justify-start";

  currentWeather: WeatherResponse = {
    city: 'NONE',
    temperature: 0,
    icon: {
      openWeatherIcon: '01d'
    }
  };

  constructor(private weatherService: WeatherService, private configService: ConfigService) { }

  ngOnInit(): void {
    this.configService.screenConfig.subscribe((config) => {
      this.weatherSectionClasses = config?.tailwindClasses?.weatherSection
    });

    this.getWeather();

    setInterval(() => {
      this.getWeather();
    }, 30 * 60_000);

  }

  getWeather() {
    this.weatherService.getWeather(this.screenId).then((response: WeatherResponse) => {
      this.currentWeather = { ...response, temperature: Math.floor(response.temperature) };
    });
  }
}
