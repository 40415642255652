<div class="{{ weatherSectionClasses }}">
  <div class="flex flex-col justify-center text-center">
    <img
      class="h-10 bg-white rounded"
      src="assets/img/weather/{{ currentWeather.icon.openWeatherIcon }}.svg"
      alt="Weather Icon"
    />

    <p class="text-8xl py-px">
      <strong>{{ currentWeather.temperature }}°</strong>
    </p>

    <p class="text-xl">
      <strong>{{ currentWeather.city.toLocaleUpperCase() }}</strong>
    </p>
  </div>
</div>
