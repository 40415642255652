<ng-template [ngIf]="!loading">
    <img class="bg-cover bg-center h-screen w-screen absolute" src="{{backgroundImgUrl}}" alt="">
  <app-logo></app-logo>
  <app-indicators [screenId]="screenId"></app-indicators>
  <app-queue [screenId]="screenId"></app-queue>
  <app-date></app-date>
  <app-weather [screenId]="screenId"></app-weather>
  <app-hour></app-hour>
  <app-slider [screenId]="screenId"></app-slider>
</ng-template>

<ng-template [ngIf]="loading">
  <div class="absolute flex flex-col items-center justify-center w-full h-full">
    <div
      class="inline-block h-20 w-20 animate-spin rounded-full border-4 border-solid border-pink border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
      role="status"
    >
      <span
        class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
      >
        Loading...
      </span>
    </div>
  </div>
</ng-template>
