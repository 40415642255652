import { Component } from '@angular/core';
import { ConfigService } from '../../services/config/config.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent {
  logoSectionClasses: string = "absolute top-[15%] w-full object-cover flex items-center justify-center";
  logoImgSrc: any = {};

  constructor(private configService: ConfigService,) { }

  ngOnInit(): void {
    this.configService.screenConfig.subscribe((config) => {
      this.logoSectionClasses = config?.tailwindClasses?.logoSection;
      this.logoImgSrc = config?.logo?.url;
    })
  }
}