import { Component } from '@angular/core';
import { EventType, Router, Event } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private router: Router) {

    const { fetch: originalFetch } = window;

    window.fetch = async (...args) => {
      let [resource, config] = args;

      const newConfig = {
        ...config,
        headers: {
          ...config?.headers,
          Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"
        }
      }

      return await originalFetch(resource, newConfig);
    };

    this.router.events.subscribe((evento: Event) => {
      if (evento.type == EventType.NavigationEnd && !evento.url.includes('register')) {
        const screenId = '1234'; //READ FROM COOKIES OR REDIRECT TO REGISTER COMPONENT

        //this.router.navigate(['screens', screenId]);
      }
    })
  }
}
