import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { SliderService } from '../../services/slider/slider.service';
import { ConfigService } from 'src/app/services/config/config.service';


@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit, OnDestroy {
  @Input() screenId!: string;
  sliderSectionClasses!: string;

  sliders: string[] = [];
  currentIndex: number = 0;
  displayText: string = "";
  intervalId: any;

  segTimeAddInSlider: number = 15000;
  segTimeRemInSlider: number = 1000;
  segTimeAddOutSlider: number = 1000;

  constructor(private sliderService: SliderService, private configService: ConfigService) { }

  async ngOnInit() {
    this.configService.screenConfig.subscribe((config) => {
      this.sliderSectionClasses = config?.tailwindClasses?.sliderSection;
    })

    await this.getSlider()
    this.startSlider();

    setInterval(() => {
      this.getSlider();
    }, 30 * 60_000);
  }

  async getSlider() {
    await this.sliderService.getSlider(this.screenId).then((response) => {
      this.sliders = response;
    });
  }

  startSlider() {
    const runSequentialSlider = async () => {
      await this.sequentialSlider(this.sliders);
      runSequentialSlider();
    };
    runSequentialSlider();
  }

  async sequentialSlider(texts: string[]): Promise<void> {
    const sliderText = document.getElementById('sliderText');
    for (const text of texts) {
      this.displayText = text;
      await this.addClassIn('animate__flipInX', this.segTimeAddInSlider);
      await this.removeClassIn('animate__flipInX', this.segTimeRemInSlider);
      await this.addClassIn('animate__flipOutX', this.segTimeAddOutSlider);
      sliderText?.classList.remove("animate__flipOutX");
    }
  }

  addClassIn(className: string, time: number): Promise<void> {
    const sliderText = document.getElementById('sliderText');
    return new Promise((resolve) => {
      sliderText?.classList.add(className);
      setTimeout(resolve, time);
    });
  }

  removeClassIn(className: string, time: number): Promise<void> {
    const sliderText = document.getElementById('sliderText');
    return new Promise((resolve) => {
      sliderText?.classList.remove(className);
      setTimeout(resolve, time);
    });
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }
}
