import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

type WeatherIconType = {
  openWeatherIcon: string
}

export interface WeatherResponse {
  city: string;
  temperature: number;
  icon: WeatherIconType;
}

@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  constructor() { }

  async getWeather(id: string): Promise<WeatherResponse> {
    const response = await fetch(`${environment.apiUrl}/screens/${id}/weather`);
    return await response.json();
  }

}
