import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config/config.service';

@Component({
  selector: 'app-hour',
  templateUrl: './hour.component.html',
  styleUrls: ['./hour.component.css']
})
export class HourComponent implements OnInit {
  hourSectionClasses: string = "absolute top-[10%] w-full flex items-center justify-center text-9xl";
  currentHour: string = new Date().toLocaleTimeString();

  constructor(private configService: ConfigService) { }

  ngOnInit(): void {
    this.configService.screenConfig.subscribe((config) => {
      this.hourSectionClasses = config?.tailwindClasses?.timeSection;
    })

    setInterval(() => {
      this.updateHour();
    }, 1000);
  }

  updateHour(): void {
    const now = new Date();
    this.currentHour = now.toLocaleTimeString();
  }

}
