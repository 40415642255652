<div class="{{ queueSectionClasses }}">
  <div class="pb-12">
    <div class="pb-3">
      <div class="text-4xl bg-pink rounded py-2">TURNO</div>
    </div>
    <div class="border border-black rounded">
      <p class="text-4xl py-2">
        <strong>{{
          queue.currentTurn.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false
          })
        }}</strong>
      </p>
    </div>
  </div>
  <div *ngIf="moduleEnabled">
    <div class="pb-3">
      <p class="text-4xl bg-pink rounded py-2"><strong>MÓDULO</strong></p>
    </div>
    <div class="border border-black rounded">
      <p class="text-4xl py-2">
        <strong>{{ queue.currentModule }}</strong>
      </p>
    </div>
  </div>
</div>
