import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from '../../services/config/config.service';
import { ScreenConfigType } from 'src/app/types/screenConfig.type';

@Component({
  selector: 'app-screen',
  templateUrl: './screen.component.html',
  styleUrls: ['./screen.component.css']
})
export class ScreenComponent implements OnInit {
  @Input() screenId!: string;
  loading: boolean = true;
  backgroundImgUrl!: string;

  constructor(private configService: ConfigService) { }

  ngOnInit(): void {
    
    this.getConfig();

    setInterval(() => {
      this.getConfig();
    }, 30 * 60_000);
  }

  getConfig(){
    this.configService.getConfig(this.screenId).then((config: ScreenConfigType) => {
      this.configService.screenConfig.next(config);
      this.backgroundImgUrl = config.backgroundImg.url;
      this.loading = false;
    });
  }
}
