import { Injectable } from '@angular/core';
import { QueueType } from 'src/app/types/queue.type';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QueueService {
  constructor() {

  }

  getCurrentQueue = async (areaId: string): Promise<QueueType> => {
    const response = await fetch(`${environment.apiUrl}/areas/${areaId}/queue`, {
      headers: {
        test: "hola"
      }
    });

    const body = await response.json();
    return body.datas;
  }

}
