import { Component, Input } from '@angular/core';
import { QueueService } from '../../services/queue/queue.service';
import { Amplify } from 'aws-amplify';
import { generateClient, ConnectionState } from 'aws-amplify/api';
import * as subscriptions from './graphql/subscriptions';
import { Observable } from 'rxjs';
import { Hub } from 'aws-amplify/utils';

import { CONNECTION_STATE_CHANGE, } from '@aws-amplify/api-graphql';
import { ConfigService } from 'src/app/services/config/config.service';
import { environment } from 'src/environments/environment';
import { QueueType } from 'src/app/types/queue.type';

Amplify.configure({
  API: {
    GraphQL: {
      endpoint: environment.realTimeApi.url,
      defaultAuthMode: 'apiKey',
      apiKey: environment.realTimeApi.apiKey
    }
  }
});

@Component({
  selector: 'app-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.css']
})
export class QueueComponent {
  @Input() screenId!: string;
  queueSectionClasses: string = "absolute top-[30%] right-[5%] text-center w-[20%]";
  moduleEnabled: boolean = true;

  queue: QueueType = {
    currentModule: 0,
    currentTurn: 0
  };

  constructor(private queueService: QueueService, private configService: ConfigService) {

    Hub.listen('api', (data: any) => {
      const { payload } = data;
      if (payload.event === CONNECTION_STATE_CHANGE) {
        const connectionState = payload.data.connectionState as ConnectionState;
        console.log('Connection state:', connectionState);
      }
    });

  }

  ngOnInit() {
    this.configService.screenConfig.subscribe((config) => {
      this.queueSectionClasses = config.tailwindClasses.queueSection;
      this.moduleEnabled = config.queue.moduleEnabled;
      config.queue.areasId.forEach((areaId) => {
        this.subscriptions(areaId);
        this.queueService.getCurrentQueue(areaId).then((response) => {
          this.queue = response;
        });
      })
    })
  }

  parseData(obj: any) {
    let data = JSON.parse(obj.subscribe.data);
    this.queue = data;
  }

  subscriptions(areaId: string) {
    let query_subscriptions = subscriptions.onScreenUpdate

    const createSub = generateClient().graphql({
      query: query_subscriptions,
      variables: {
        name: `areas/${areaId}/queue:updates`
      }
    }) as Observable<any>;

    createSub.subscribe({
      next: ({ data }: any) => (
        this.parseData(data),
        this.playSound()
      ),
      error: (error: any) => console.warn(error)
    });
  }

  playSound() {
    const audio = new Audio();
    audio.src = 'assets/llamado.mp3';
    audio.load();
    audio.play();
  }

}