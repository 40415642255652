import { Component } from '@angular/core';
import { ConfigService } from 'src/app/services/config/config.service';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.css']
})
export class DateComponent {
  dateSectionClasses: string = "absolute top-[50%] w-[45%] flex justify-end";
  currentDate: string = "";
  diaSemana: string = "";
  dia: number = 0;
  mes: string = "";
  anio: number = 0;

  constructor(private configService: ConfigService) { }

  ngOnInit(): void {
    this.configService.screenConfig.subscribe((config) => {
      this.dateSectionClasses = config?.tailwindClasses?.dateSection;
    })
    this.getCurrentDate();
    setInterval(() => {
      this.getCurrentDate();
    }, 1000);
  }

  getCurrentDate(): void {
    const now = new Date();

    const diasSemana = ['DOMINGO', 'LUNES', 'MARTES', 'MIÉRCOLES', 'JUEVES', 'VIERNES', 'SÁBADO'];
    this.diaSemana = diasSemana[now.getDay()];

    this.dia = now.getDate();

    const meses = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];
    this.mes = meses[now.getMonth()];

    this.anio = now.getFullYear();
  }

}
