import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SliderService {

  constructor() { }

  async getSlider(id: string): Promise<string[]> {
    const response = await fetch(`${environment.apiUrl}/screens/${id}/slider`);
    return await response.json();
  }
}
