import { Component, Input, OnInit } from '@angular/core';
import { IndicatorsService } from '../../services/indicators/indicators.service';
import { ConfigService } from 'src/app/services/config/config.service';

@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.css']
})

export class IndicatorsComponent implements OnInit {
  @Input() screenId: string = "";
  indicatorsSectionClasses: string = "absolute top-[30%] left-[5%] w-[20%]";

  _configIndicators: any = [];
  get configIndicators(): any {
    return this._configIndicators;
  }
  set configIndicators(value: any) {
    this._configIndicators = value;
    this.colorPercentage();
  }

  constructor(private indicatorsService: IndicatorsService, private configService: ConfigService) { }

  ngOnInit() {

    this.configService.screenConfig.subscribe((config) => {
      this.indicatorsSectionClasses = config?.tailwindClasses?.indicatorsSection;
    });

    this.getIndicators();

    setInterval(() => {
      this.getIndicators();
    }, 30 * 60_000);
  }

  getIndicators() {
    this.indicatorsService.getIndicators(this.screenId).then(response => {
      this.configIndicators = response.map(Indicator => ({
        ...Indicator,
        name: Indicator.name ?? "",
        value: Indicator.value ?? "",
        percentage: Indicator.percentage ?? "-0,1%",
      }));
    });
  }

  colorPercentage() {
    this._configIndicators.forEach((item: { percentageValue: number; percentage: string; }) => {
      item.percentageValue = Number(item.percentage.replace(/[^\d.-]/g, ''));
    });
  }

}
