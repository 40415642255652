import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';


interface Indicator {
  name?: string;
  value?: string;
  percentage?: string;
}

@Injectable({
  providedIn: 'root'
})
export class IndicatorsService {

  constructor() { }

  async getIndicators(id: string): Promise<Indicator[]> {
    const response = await fetch(`${environment.apiUrl}/screens/${id}/indicators`);
    return await response.json();
  }
}